import Config from "./config";
import ContractABI from "./abi/rebornapeking.json";

import { Contract } from "ethers";

class RebornApeKing extends Contract {
  constructor(provider) {
    super(Config.contractAddress, ContractABI, provider);
  }
}

export default RebornApeKing;
