import Button from "./Button";
import { useState, useEffect } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { injected } from "lib/connectors";
import toast from "react-hot-toast";
import config from "lib/config";
import { useEagerConnect, useInactiveListener } from "lib/hooks/useEagerConnect";

const ConnectButton = () => {
  const [activatingConnector, setActivatingConnector] = useState();
  const { connector, activate, deactivate, error, account, chainId } = useWeb3React();

  const compressAddress = (address) =>
    address.substr(0, 12) + "..." + address.substr(address.length - 4, address.length);

  useEffect(() => {
    if (chainId && chainId !== 1) {
      toast.error("Change the network to Mainnet");
      deactivate();
    }
  }, [chainId, deactivate]);

  const onConnectClick = () => {
    if (connector === injected) {
      deactivate();
    } else {
      setActivatingConnector(injected);
      activate(injected);
    }
  };

  useEffect(() => {
    if (activatingConnector && error) {
      if (error instanceof UnsupportedChainIdError) toast.error("Change the network to Mainnet");
      if (connector && connector.walletConnectProvider) {
        connector.walletConnectProvider = undefined;
      }
      deactivate();
    }

    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector, deactivate, error]);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);
  return (
    <>
      {account ? (
        <Button onClick={() => deactivate()}>{compressAddress(account)}</Button>
      ) : (
        <Button onClick={() => onConnectClick()}>Connect Wallet</Button>
      )}
    </>
  );
};

export default ConnectButton;
