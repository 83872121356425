import Title from "components/Title";
import styled from "styled-components";
import WuKongImg from "static/images/monkeyking.png";

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .description {
    margin-right: 50px;
    font-size: inherit;
    line-height: 150%;
    margin-bottom: 2rem;
  }
  @media (max-width: 700px) {
    .description {
      margin-right: 0;
    }
  }
`;

const Highlight = styled.span`
  color: yellow;
`;

const About = () => {
  return (
    <AboutContainer id="about">
      <Title>About</Title>
      <div className="description">
        Welcome to the Journey of the <Highlight>Reborn Ape King</Highlight>. Throughout this dynamic storytelling
        collection, we present a set of <Highlight>10,000</Highlight> Ape King collectibles expressing the extraordinary
        journey of <Highlight>Sun Wu Kong</Highlight> from long years of spiritual cultivation and rebels against the
        primacy of heaven. Our collection describes the journey of the Ape King with a set of 72 transformation skills
        that Sun Wu Kong inherits and quickly masters a set of secret oral formulas. These abilities have given him the
        ability to live through the three calamities of thunder, fire, and wind. Sun Wu Kong is taken to heaven to be
        executed for his crimes, but no amount of fire, lightning, and edged weapons have any effect on his
        invincibility. The story of the <Highlight>Ape King</Highlight> has enthralled people from all over the world
        for centuries. For the first time ever, this story is brought to the Ethereum blockchain becoming the first
        artistic creation of the legendary Reborn Ape King. The Ape King tale has been deeply adapted in all forms of
        NFT projects. This space cannot be present without the incredible Journey of the original{" "}
        <Highlight>Reborn Ape King, Sun Wu Kong</Highlight>.
      </div>
      <img src={WuKongImg} alt="wukong" width="500px" />
    </AboutContainer>
  );
};

export default About;
