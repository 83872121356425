import Title from "components/Title";
import styled from "styled-components";
import StepImage from "static/images/step.png";
import DiagramImage from "static/images/diagram.png";
import FlexBox from "components/common/FlexBox";
import PeachImg from "static/images/peach.jpg";
import { Chrono } from "react-chrono";

const StyledStepItem = styled(FlexBox)`
  margin-bottom: 1rem;

  .step-tick {
    position: relative;
    margin-right: 2rem;
    .tick-caption {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
    }
  }
`;

const RoadmapContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 200%;
  .peach-img {
    display: flex;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 50%;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .info-content {
    margin-bottom: 2rem;
  }
`;

const RoadmapContainer = styled.div`
  padding: 1rem;
  .post-launch-content {
    font-size: 1.7rem;
  }
  .post-launch-item {
    .year-tag {
      white-space: nowrap;
      margin-right: 2rem;
    }

    @media (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      .year-tag {
        margin-right: 0;
      }
    }
  }
`;

const Token = styled.span`
  color: #f771f0;
`;

const RoadmapItem = ({ tick, children }) => {
  return (
    <StyledStepItem flexDirection="row" alignItems="center">
      <div className="step-tick">
        <img src={StepImage} alt="step" width="100px" />
        <div className="tick-caption">{tick}</div>
      </div>
      <div className="description">{children}</div>
    </StyledStepItem>
  );
};

const Highlight = styled.span`
  color: yellow;
`;

const Roadmap = () => {
  const roadmap = [
    {
      title: "Q4 2021",
      cardTitle: "NFT Auction",
      cardSubtitle:
        "The NFT Auction will take place after the successful sell-out of our 10,000 Reborn Ape King collection. The more release of the legendary Apes will be auctioned after our public sale. As mentioned earlier, these will be redeemable using your $PEACH tokens and RAK NFT’s. Once the tokens are transferred to the contract, they will be burned once the legendary Apes are claimed.",
    },
    {
      title: "Q1 2022",
      cardTitle: "Bull Demon King NFT",
      cardSubtitle:
        "The Reborn Ape King journey does not end here. We are bringing more universes to the journey. The Bull Demon King NFT’s were known to be the great sage who pacifies the heavens along Sun Wu Kong’s journey. Bull Demon King This extension of our project will be entering the blockchain at the beginning of Q1 of 2022. This will be redeemable using your $PEACH tokens and RAK NFT’s.",
    },
    {
      title: "Q2 2022",
      cardTitle: "Play to Earn Game",
      cardSubtitle:
        "With our Ape King vs Bull Demon King extension coming out in early 2022, the goal is to have your Reborn Ape King NFT take many shapes inside virtual worlds: characters, powers, personalization features, and more. You will be able to earn the most out of your valuable items by playing the game very well and can sell them for real-world money on your own terms. While play-to-earn implementation is still an emerging concept for us at Reborn Ape King, it could redefine more than just a NFT project for us.",
    },
  ];
  return (
    <RoadmapContainer id="roadmap">
      <Title>Roadmap</Title>
      <RoadmapContent>
        <RoadmapItem tick="0%">
          The Ape King is reborn, 5 Random apes would be airdropped to 5 early random community members. Kick-off the
          yielding.
        </RoadmapItem>
        <RoadmapItem tick="20%">Community raffle to win 1 ETH. Ramp up our social media management.</RoadmapItem>
        <RoadmapItem tick="40%">
          Giveaway apes to discord & twitter farm. Community raffle to win another 1 ETH. Open the community wallet.
        </RoadmapItem>
        <RoadmapItem tick="60%">
          Open the community multisig wallet. 25 ETH would be added to the community wallet for development and
          marketing.
        </RoadmapItem>
        <RoadmapItem tick="80%">Reveal! The Short 2D movie competition for the Ape King to win 2 ETH.</RoadmapItem>
        <RoadmapItem tick="100%">
          Yay! Sold out. Another 25 ETH would be added to the community wallet. Prepare the NFT Auction. Get featured on
          the Rarity tools.
        </RoadmapItem>
      </RoadmapContent>
      <Title>Post-launch</Title>
      {/* <RoadmapContent>
        <div style={{ marginBottom: 50 }}>
          We are launching the Bull Demon King NFTs towards the beginning of Q1 2022. This will be redeemable using your
          $PEACH tokens and RAK NFT’s. Also, the NFT Auction will take place after the successful sell-out of our 10,000
          Reborn Ape King collection.
        </div>
        <FlexBox flexDirection="column" justifyContent="center" className="post-launch-content" gap={40}>
          <FlexBox flexDirection="row" className="post-launch-item">
            <div className="year-tag">2021 Q4:</div>
            <div>Launch the NFT Auction</div>
          </FlexBox>
          <FlexBox flexDirection="row" className="post-launch-item">
            <div className="year-tag">2022 Q1:</div>
            <div>Bull Demon King NFTs are entering the Blockchain</div>
          </FlexBox>
          <FlexBox flexDirection="row" className="post-launch-item">
            <div className="year-tag">2022 Q2:</div>
            <div>
              Develop the Play to Earn Game (<Highlight>Ape King</Highlight> vs <Highlight>Bull Demon King</Highlight>)
            </div>
          </FlexBox>
        </FlexBox>
      </RoadmapContent> */}
      <RoadmapContent>
        <Chrono
          mode="VERTICAL"
          items={roadmap}
          theme={{
            primary: "white",
            secondary: "transparent",
            cardBgColor: "#161832",
          }}
          hideControls={true}
        />
      </RoadmapContent>
      <Title>
        🍑<Token>$PEACH</Token> - Peach of Immortality
      </Title>
      <RoadmapContent>
        <div style={{ marginBottom: 50, lineHeight: "2rem" }}>
          It is important to consider that most NFT projects solely allow you to stake your NFT’s. From there, users are
          forced to pay gas fees to the blockchain to stake their NFT piece, locking it into the blockchain not allowing
          you to trade it on secondary markets. With a Reborn Ape King, the focus is on holding. This allows users to
          passively earn 🍑$PEACH simply by <Highlight>HODLing</Highlight> dearly to their Reborn Ape King NFT. <br />
          <img src={PeachImg} alt="peach" className="peach-img" />
          One way of adopting more 🍑$PEACH tokens is by purchasing more tokens from SushiSwap. By adding 🍑$PEACH/ETH
          LP to SushiSwap and staking it to the liquidity mining pool to harvest even more 🍑$PEACH tokens.
          <br />
          <br />A total of <Highlight>50,000</Highlight> 🍑$PEACH tokens and <Highlight>$100,000</Highlight> will be
          added to SushiSwap’s initial liquidity pool. From there, 10% of the royalty fees from the second market will
          be used for liquidity maintenance. The liquidity mining program will provide more stability to the liquidity
          pool for the 🍑$PEACH token. Our mission is beyond utility but building the DAO with the help of our 🍑$PEACH
          token.
        </div>

        <div className="info-content">
          <div>
            $PEACH Initial Price: <Highlight>$2</Highlight>
          </div>
          <div>
            $PEACH Daily: <Highlight>10</Highlight>
          </div>
          <div>
            Mint Bonus: 200 $PEACH's (<Highlight>$400</Highlight>)
          </div>
        </div>

        <div style={{ display: "flex" }}>
          To get more information about our project.{" "}
          <a
            href="https://rebornapeking.medium.com/introduction-to-reborn-ape-king-aae97cbc4287"
            target="_blank"
            rel="noreferrer"
            style={{ display: "flex", marginLeft: 5 }}
          >
            <u>Read this medium article</u>
          </a>
        </div>
      </RoadmapContent>
      <Title>
        How to Earn & Use <Token>$PEACH</Token>
      </Title>
      <RoadmapContent style={{ lineHeight: "300%" }}>
        <img src={DiagramImage} alt="diagram" width="100%" />
      </RoadmapContent>
    </RoadmapContainer>
  );
};

export default Roadmap;
