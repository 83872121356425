import React from "react";
import MainWrapper from "./views/MainWrapper";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

const getLibrary = (provider) => {
  return new Web3Provider(provider);
};

const App = () => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MainWrapper />
    </Web3ReactProvider>
  );
};

export default App;
