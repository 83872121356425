import FlexBox from "components/common/FlexBox";
import styled from "styled-components";
import { FaDiscord, FaTwitter, FaMediumM, FaYoutube } from "react-icons/fa";
import Opensea from "static/icons/opensea.svg";

const SocialItems = styled.a`
  padding-top: 0;
  padding: 0 0.75rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
`;

const Social = () => {
  return (
    <FlexBox alignItems="center">
      <SocialItems href="https://rebornapeking.medium.com/" target="_blank" rel="noreferrer">
        <FaMediumM />
      </SocialItems>
      <SocialItems href="https://twitter.com/rebornapeking" target="_blank" rel="noreferrer">
        <FaTwitter />
      </SocialItems>
      <SocialItems href="https://discord.gg/rebornapeking" target="_blank" rel="noreferrer">
        <FaDiscord />
      </SocialItems>
      <SocialItems href="https://opensea.io/collection/rebornapeking/" target="_blank" rel="noreferrer">
        <img src={Opensea} width="20px" alt="opensea" />
      </SocialItems>
      <SocialItems href="https://www.youtube.com/channel/UCKTbzOP9E46jPexaLFg7Khg" target="_blank" rel="noreferrer">
        <FaYoutube />
      </SocialItems>
      {/* <SocialItems>
            <Button>Connect Wallet</Button>
          </SocialItems> */}
    </FlexBox>
  );
};
export default Social;
