import FlexBox from "components/common/FlexBox";
import BrandImg from "static/images/brand.png";
import styled from "styled-components";
import Social from "views/header/social";

const StyledFlexBox = styled(FlexBox)`
  border-top: 1px solid #eee;
  padding-top: 2rem;
  margin: 6rem 1rem 2rem 1rem;
  font-size: 1rem;
  .brand {
    margin-bottom: 0.5rem;
    @media (max-width: 900px) {
      font-size: 0.7rem;
    }
  }
  .logo {
    margin: 0 0.5rem;
  }
  @media (max-width: 600px) {
    margin-top: 4rem;
  }
`;

const Footer = () => {
  return (
    <StyledFlexBox justifyContent="space-between" flexDirection="row" alignItems="center">
      <div>
        <div className="brand">© 2021 The Reborn Ape King</div>
      </div>
      <div className="logo">
        <img src={BrandImg} alt="logo" width="80px" />
      </div>
      <Social />
    </StyledFlexBox>
  );
};

export default Footer;
