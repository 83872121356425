import Title from "components/Title";
import UnknownImage from "static/images/unknown.gif";
import FlexBox from "components/common/FlexBox";
import styled from "styled-components";
import { useState, useEffect } from "react";
import ConnectButton from "components/common/ConnectButton";
import { useWeb3React } from "@web3-react/core";
import Config from "lib/config";
// import { Line } from "rc-progress";
import { Tabs, TabHead, Content } from "components/common/Tab";
import ApeKing from "lib/RebornApeKing";
import { useMemo, useCallback } from "react";
import Button from "components/common/Button";
import BigNumber from "bignumber.js";
import toast from "react-hot-toast";

const MintContainer = styled.div`
  .mint-container {
    img {
      margin-right: 5rem;
      object-fit: contain;
    }
    .mint-button {
      justify-content: flex-end;
      @media (max-width: 1000px) {
        justify-content: center;
        margin-bottom: 2rem;
      }
    }
    .launch-caption {
      font-size: 1.5rem;
      margin-top: 2rem;
    }
    @media (max-width: 1000px) {
      text-align: center;
      flex-direction: column;
      justify-content: center;
      img {
        margin: auto;
        margin-bottom: 2rem;
      }
      .launch-caption {
        font-size: 1rem;
      }
    }
    .mint-right {
      width: 100%;
    }
    .count-box {
      display: flex;
      margin: 0.5rem 0;
      input {
        margin: 0 1rem;
        width: 80px;
        background: black;
        color: white;
        border: 1px solid white;
        font-size: inherit;
        text-align: center;
      }
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`;

const CountdownContainer = styled.div`
  font-size: 3rem;
  margin-top: 3rem;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
  .tick_info {
    font-size: 1.8rem;
    @media (max-width: 600px) {
      font-size: 0.8rem;
    }
  }
  .sub-title {
    margin-top: 1rem;
    font-size: 2rem;
  }
`;

const Countdown = ({ startTime }) => {
  const [leftSec, setLeftSec] = useState(0);
  const [day, setDay] = useState(0);
  const [min, setMin] = useState(0);
  const [hour, setHour] = useState(0);
  const [sec, setSec] = useState(0);

  const formatDigits = (num) => {
    if (parseInt(num) < 10) return "0" + num;
    return num;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const time = Math.round(new Date().getTime() / 1000);
      const leftSec = startTime - time;

      if (leftSec < 0) leftSec = 0;

      setLeftSec(leftSec);
      setDay(Math.floor(leftSec / 60 / 60 / 24));
      setHour(Math.floor(leftSec / 60 / 60) % 24);
      setMin(Math.floor(leftSec / 60) % 60);
      setSec(leftSec % 60);
    }, 1000);

    return () => clearInterval(timer);
  }, [leftSec, startTime]);

  return (
    <CountdownContainer>
      <div className="title">Countdown</div>
      <FlexBox alignItems="center" justifyContent="center" flexWrap="wrap">
        <div className="day">
          {formatDigits(day)}
          <span className="tick_info"> days</span>:
        </div>
        <div className="hour">
          {formatDigits(hour)}
          <span className="tick_info"> hours</span>:
        </div>
        <div className="min">
          {formatDigits(min)}
          <span className="tick_info"> mins</span>:
        </div>
        <div className="sec">
          {formatDigits(sec)}
          <span className="tick_info"> secs</span>
        </div>
      </FlexBox>
      <div className="sub-title">Left until the presale</div>
    </CountdownContainer>
  );
};

const MintTabContainer = styled.div`
  line-height: 150%;
  .mint-button {
    margin-top: 1rem;
  }
  .mint-description {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }
  .mint-price {
    margin-bottom: 0.5rem;
  }
  progress {
    width: 100%;
  }
  .info {
    margin-top: 2rem;
    font-size: 1.6rem;
  }
`;

const MintInfoContainer = ({ mintPrice, minted, max, onClicked, premiumPurchased, isPremium, maxForSingleTx }) => {
  const [count, setCount] = useState(1);

  const formatPrice = (price) => {
    return new BigNumber(price).dividedBy(1e18).toString();
  };

  const doMinus = () => {
    setCount(Math.max(count - 1, 1));
  };

  const doPlus = () => {
    setCount(Math.min(count + 1, maxForSingleTx));
  };

  return (
    <>
      <div className="mint-price">
        Mint Price: <span style={{ color: "yellow" }}>{formatPrice(mintPrice)} ETH</span>
      </div>
      <div>
        Minted: {minted} / {max}
      </div>
      <div className="progressBar">
        <progress value={(minted / max) * 100} max="100" />
      </div>
      {isPremium && <div>Premium Purchased: {premiumPurchased} / 5</div>}
      <div className="count-box">
        <Button onClick={() => doMinus()}>-</Button>
        <input type="number" min={1} readOnly max={maxForSingleTx} step={1} value={count} />
        <Button onClick={() => doPlus()}>+</Button>
      </div>
      <Button onClick={() => onClicked(count)} className="mint-button">
        Mint
      </Button>
    </>
  );
};

const MintTab = () => {
  const { account, library } = useWeb3React();
  const [active, setActive] = useState(0);
  const [normalMinted, setNormalMinted] = useState(0);
  const [premiumMinted, setPremiumMinted] = useState(0);
  const [normalMax, setNormalMax] = useState(9800);
  const [premiumMax, setPremiumMax] = useState(200);
  const [isEligableForPremium, setEligableForPremium] = useState(false);
  const [normalMintPrice, setNormalMintPrice] = useState(0);
  const [premiumMintPrice, setPremiumMintPrice] = useState(0);
  const [premiumPurchased, setPremiumPurchased] = useState(0);
  const [whitelisted, setWhitelisted] = useState(false);
  const [isPublicsale, setIsPublicsale] = useState(false);
  const [maxApeMint, setMaxApeMint] = useState(1);
  const [premiumMaxApeMint, setPremiumMaxApeMint] = useState(1);

  const apeKing = useMemo(() => {
    if (library) {
      return new ApeKing(library.getSigner());
    }
  }, [library]);

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  const loadInfo = useCallback(async () => {
    if (!apeKing || !account) return;
    setNormalMinted((await apeKing.normalMinted()).toString());
    setPremiumMinted((await apeKing.premiumMinted()).toString());
    setNormalMax((await apeKing.normalMaxSupply()).toString());
    setPremiumMax((await apeKing.premiumMaxSupply()).toString());
    setEligableForPremium(await apeKing.isEligableForPremium(account));

    const _mintPrice = await apeKing.mintPrice();
    setNormalMintPrice(_mintPrice.toString());
    setPremiumMintPrice(_mintPrice.div(2).toString());
    setPremiumPurchased((await apeKing.premiumPurchased(account)).toString());

    setWhitelisted(await apeKing.whitelisted(account));
    setIsPublicsale(await apeKing.publicSaleStarted());

    setMaxApeMint((await apeKing.maxApePurchase()).toString());
    setPremiumMaxApeMint((await apeKing.premiumMaxApePurchase()).toString());
  }, [apeKing, account]);

  useEffect(() => {
    loadInfo();
  }, [apeKing, account]);

  const doNormalMint = async (count) => {
    try {
      await loadInfo();
      if (normalMax != 0 && normalMinted === normalMax) {
        toast.error("Sold out");
        return;
      }
      await (
        await apeKing.mintApeKing(count, { value: new BigNumber(normalMintPrice).multipliedBy(count).toString() })
      ).wait();
      await loadInfo();
    } catch (err) {
      console.log(err);
      toast.error("Transaction Reverted");
    }
  };

  const doPremiumMint = async (count) => {
    try {
      await loadInfo();
      if (normalMax != 0 && normalMinted === normalMax) {
        toast.error("Sold out");
        return;
      }
      await (
        await apeKing.mintApeKingPremium(count, {
          value: new BigNumber(premiumMintPrice).multipliedBy(count).toString(),
        })
      ).wait();
      await loadInfo();
    } catch (err) {
      toast.error("Transaction Reverted");
    }
  };

  return (
    <div>
      <Tabs>
        <TabHead onClick={handleClick} active={active === 0} id={0}>
          Mint
        </TabHead>
        <TabHead onClick={handleClick} active={active === 1} id={1}>
          Mint Premium
        </TabHead>
      </Tabs>
      <Content active={active === 0}>
        {account ? (
          normalMax != 0 && normalMinted < normalMax ? (
            <MintTabContainer>
              <div className="mint-description">You can mint MAX 10 Ape Kings in one transaction</div>

              <MintInfoContainer
                mintPrice={normalMintPrice}
                minted={normalMinted}
                max={normalMax}
                maxForSingleTx={maxApeMint}
                onClicked={doNormalMint}
              />
            </MintTabContainer>
          ) : (
            <div style={{ fontSize: "3rem", marginTop: "2rem" }}>Sold out</div>
          )
        ) : (
          <div>Connect your wallet to mint</div>
        )}
      </Content>
      <Content active={active === 1}>
        <MintTabContainer>
          {account ? (
            isEligableForPremium ? (
              normalMax != 0 && normalMinted < normalMax ? (
                <MintInfoContainer
                  isPremium={true}
                  mintPrice={premiumMintPrice}
                  minted={premiumMinted}
                  max={premiumMax}
                  premiumPurchased={premiumPurchased}
                  onClicked={doPremiumMint}
                  maxForSingleTx={premiumMaxApeMint}
                />
              ) : (
                <div style={{ fontSize: "3rem", marginTop: "2rem" }}>Sold out</div>
              )
            ) : (
              <div className="info">You are not eligable for the premium mint</div>
            )
          ) : (
            <div style={{ color: "yellow" }}>Connect your wallet to mint</div>
          )}
        </MintTabContainer>
      </Content>
    </div>
  );
};

const Mint = () => {
  return (
    <MintContainer id="mint">
      <Title>Mint</Title>
      <FlexBox flexDirection="row" className="mint-container">
        <img src={UnknownImage} alt="unknown" width="32%" className="mint-image" />
        {/* <div className="mint-right">
          <Countdown startTime={Config.saleTime.presale} />
        </div> */}
        <div className="mint-right">
          <FlexBox className="mint-button">
            <ConnectButton />
          </FlexBox>
          <MintTab />
        </div>
      </FlexBox>
    </MintContainer>
  );
};
export default Mint;
