import FlexBox from "components/common/FlexBox";
import Title from "components/Title";
import styled from "styled-components";
import Founder from "static/images/team/founder.png";
import HeadOfStaff from "static/images/team/headofstaff.png";
import Artist from "static/images/team/artist.png";
import Community from "static/images/team/community.png";
import Designer from "static/images/team/designer.png";
import Marketing from "static/images/team/marketinglead.png";
import { FaTwitter } from "react-icons/fa";

const StyledFlexBox = styled(FlexBox)`
  margin: 0 2rem;
  img {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    width: 200px;

    @media (max-width: 600px) {
      width: 80%;
    }
  }
  .card-title {
    margin-top: 0.8rem;
  }
  .card-subtitle {
    margin-top: 0.2rem;
    font-size: 1rem;
    color: #2edfb7;
  }
`;

const Card = ({ src, title, subtitle, twitter }) => {
  return (
    <StyledFlexBox flexDirection="column" alignItems="center" className="card-item">
      <img src={src} alt={title} width="100%" />
      <div className="card-title">{title}</div>
      <FlexBox alignItems="center">
        <div className="card-subtitle">{subtitle}</div>
        {twitter && (
          <a
            href={`https://twitter.com/${twitter}`}
            target="_blank"
            rel="noreferrer"
            style={{ display: "flex", marginLeft: 5 }}
          >
            <FaTwitter />
          </a>
        )}
      </FlexBox>
    </StyledFlexBox>
  );
};

const TeamContainer = styled(FlexBox)`
  @media (max-width: 600px) {
    flex-direction: column;
    .card-item {
      margin-bottom: 1rem;
    }
  }
`;

const Team = () => {
  return (
    <div id="team">
      <Title>Team</Title>
      <TeamContainer flexDirection="row" justifyContent="center" flexWrap="wrap">
        <Card src={Founder} title="Founder" subtitle="Sun Wu Kong - Former CTO" />
        <Card src={HeadOfStaff} title="Head of Staff" />
        <Card src={Artist} title="Senior Artist" />
        <Card src={Designer} title="Graphics Designer" />
        <Card src={Community} title="Community Lead" subtitle="Tang San Zhang" />
        <Card src={Marketing} title="Marketing Manager" />
      </TeamContainer>
    </div>
  );
};

export default Team;
