import styled, { css } from "styled-components";
import Loading from "./Loading";
const StyledButton = styled.button`
  background: transparent;
  border: 1px solid white;
  color: white;
  padding: 5px 20px;
  ${({ bold }) =>
    bold &&
    css`
      font-weight: bold;
    `}
  white-space: nowrap;
  font-size: ${({ size }) => (size ? `${size}px` : "20px")};
  ${({ long }) =>
    long &&
    css`
      padding: 5px 32px;
    `}
  ${({ sm }) =>
    sm &&
    css`
      padding: 5px 8px;
      font-size: 11px;
    `}
  font-family: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > div {
    margin-right: 5px;
  }
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:disabled {
    background: transparent;
    color: #808080;
    border: 1px solid #808080;
    cursor: not-allowed;
  }
  &:hover:enabled {
    border: 1px solid #bbb;
    color: #bbb;
  }
`;

const Button = ({ size, loading, secondary, children, ...rest }) => {
  return (
    <StyledButton size={size} secondary={secondary} {...rest}>
      {loading && <Loading />}
      {children}
    </StyledButton>
  );
};

export default Button;
