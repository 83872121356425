import styled from "styled-components";

export const Tabs = styled.div`
  overflow: hidden;
  background: transparent;
  height: 3em;
`;

export const TabHead = styled.button`
  cursor: pointer;
  position: relative;
  background: transparent;
  color: white;

  margin-right: 0.1em;
  font-size: inherit;
  font-family: inherit;
  border: none;
  border-bottom: ${({ active }) => (active ? "1px solid #eee" : "")};
  transition: background-color 0.5s ease-in-out;
  padding: 0.5rem;
  margin-right: 1rem;

  :hover {
    border-bottom: 1px solid white;
  }
`;
export const Content = styled.div`
  ${(props) => (props.active ? "" : "display:none")}
`;
