import React from "react";
import { Toaster } from "react-hot-toast";
import Header from "./header";
import Footer from "./footer";
import Container from "./container";
import styled from "styled-components";

const StyledWrapper = styled.div`
  width: 60%;
  margin: auto;
  @media (max-width: 1600px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    width: 100%;
    .contract-address {
      font-size: 0.8rem;
    }
  }
`;

const MainWrapper = () => {
  return (
    <StyledWrapper>
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 3000,
        }}
      />
      <div className="contract-address" style={{ textAlign: "center" }}>
        Official contract address:{" "}
        <a
          href="https://etherscan.io/address/0xa0a6c9354046a68529b339e3123e261f9e12bc68"
          target="_blank"
          rel="noreferrer"
        >
          0xA0A6c9354046a68529B339e3123e261F9e12bC68
        </a>
      </div>
      <Header />
      <Container />
      <Footer />
    </StyledWrapper>
  );
};

export default MainWrapper;
