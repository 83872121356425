import styled from "styled-components";
import BgImage from "static/images/background.jpg";

import Mint from "./mint";
import About from "./about";
import Roadmap from "./roadmap";
import Team from "./team";
import FAQ from "./faq";
import Gallery from "./gallery";
import Overview from "./overview";
import Trailer from "./trailer";

const StyledContainer = styled.div`
  .banner {
    width: 100%;
  }
`;

const Container = () => {
  return (
    <StyledContainer>
      <div className="banner">
        <img src={BgImage} alt="landing" width="100%" />
      </div>
      <Trailer />
      <Mint />
      <Gallery />

      <About />
      {/* <Overview /> */}
      <Roadmap />
      <FAQ />
      <Team />
    </StyledContainer>
  );
};

export default Container;
