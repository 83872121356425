import Accordion from "components/common/Accordion";
import Title from "components/Title";

const FAQ = () => {
  return (
    <div id="faq">
      <Title>Faq</Title>
      <Accordion title="1. What is the Reborn Ape King?">
        The Reborn Ape King is a collection of 10000 ape king NFTs—unique digital collectibles.
      </Accordion>
      <Accordion title="2. How to connect the metamask?">
        Here is the Youtube video introducing how to safely use the metamask.{"  "}
        <a href="https://www.youtube.com/watch?v=yWfZnjkhhhg" target="_blank" rel="noreferrer">
          https://www.youtube.com/watch?v=yWfZnjkhhhg
        </a>
      </Accordion>
      <Accordion title="3. What is the RebornApeKing contract address?">
        The official contract address is{" "}
        <a
          href="https://etherscan.io/address/0xa0a6c9354046a68529b339e3123e261f9e12bc68"
          target="_blank"
          rel="noreferrer"
        >
          0xA0A6c9354046a68529B339e3123e261F9e12bC68
        </a>
      </Accordion>
      <Accordion title="4. How many Ape Kings are there?">
        There are 10000 unique Ape Kings. 9800/200 Ape Kings are allocated to the Normal/Premium sale.
      </Accordion>
      <Accordion title="5. How many traits are there?">
        There are over 200 unique traits in the Reborn Ape King. All traits are hand-drawn by our senior artist.
      </Accordion>
      <Accordion title="6. Will the team own the rare Ape Kings?">
        No. We don’t own the super-rare Apes. All are completely random and will be distributed randomly.
      </Accordion>
      <Accordion title="7. When can we mint the Ape Kings?">
        The official public sale will be available on our official website in the middle of Nov. Our pre-sale will also
        be live on our website 4 days in advance of the public sale.
      </Accordion>
      <Accordion title="8. How to mint the Ape Kings?">
        You can mint the Apes in the pre-sale and public sale on our official website. Connect the metamask on our
        website and do click the mint button.
      </Accordion>
      <Accordion title="9. Who are eligible for the premium mint?">
        You should have at least 1 BAYC, 1 Coolcat, 1 MekaVerse, or 1 Doge Pound NFT.
      </Accordion>
      <Accordion title="10. How much is the mint price?">
        The Ape Kings will be sold at 0.072/0.036 ETH per one in the normal/premium sale. The mint price for the presale
        is 0.05/0.025 ETH.
      </Accordion>
      <Accordion title="11. How many Ape Kings can I mint for a single transaction?">
        You can mint 10 ape kings in the normal sale and 5 in the premium sale for a single transaction.
      </Accordion>
      <Accordion title="12. How many NFTs can I mint per one address in the premium sale?">
        You can mint only 5 Ape Kings per one address in the premium sale.
      </Accordion>
    </div>
  );
};

export default FAQ;
