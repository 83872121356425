import GalleryImg from "static/images/gallery/gallery.jpg";
import styled, { keyframes } from "styled-components";
import Title from "components/Title";

const loop = keyframes`
	0% {
			transform: translateY(0px);
	}
	100% {
			transform: translateY(-50%);
	}
`;

const StyledContainer = styled.div`
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5rem;
  height: 400px;
  mask-image: linear-gradient(transparent, black 20%, black 80%, transparent 100%);
  .moving-banner {
    animation: 60s linear 0s infinite normal none running ${loop};
  }
`;

const Gallery = () => {
  return (
    <div id="gallery">
      <Title>Gallery</Title>
      <StyledContainer>
        <div className="moving-banner">
          <img src={GalleryImg} alt="banner" width="100%" />
          <img src={GalleryImg} alt="banner" width="100%" />
        </div>
      </StyledContainer>
    </div>
  );
};

export default Gallery;
