import Title from "components/Title";
import OverviewImg from "static/images/gallery/overview.jpg";
import styled from "styled-components";

const StyledContainer = styled.div`
  /* margin: 2rem 0; */
`;

const Overview = () => {
  return (
    <div id="overview">
      <Title>Overview</Title>
      <StyledContainer>
        <img src={OverviewImg} width="100%" alt="overview" />
      </StyledContainer>
    </div>
  );
};

export default Overview;
