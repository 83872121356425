import FlexBox from "components/common/FlexBox";
import styled from "styled-components";
import BrandImg from "static/images/brand.png";
import Social from "./social";

const StyledHeader = styled(FlexBox)`
  padding: 2rem 0;
  @media (max-width: 768px) {
    flex-direction: column;
    .menu {
      margin-bottom: 1rem;
    }
  }
`;

const MenuItems = styled.div`
  padding: 0 0.75rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Header = () => {
  return (
    <StyledHeader flexDirection="row" justifyContent="space-between" alignItems="center">
      <div>
        <img src={BrandImg} alt="logo" width="80px" />
      </div>
      <FlexBox alignItems="center" className="menu" flexWrap="wrap" justifyContent="center">
        <MenuItems>
          <a href="#trailer">Trailer</a>
        </MenuItems>
        <MenuItems>
          <a href="#mint">Mint</a>
        </MenuItems>
        <MenuItems>
          <a href="#gallery">Gallery</a>
        </MenuItems>
        <MenuItems>
          <a href="#about">About</a>
        </MenuItems>
        {/* <MenuItems>
          <a href="#overview">Overview</a>
        </MenuItems> */}
        <MenuItems>
          <a href="#roadmap">Roadmap</a>
        </MenuItems>
        <MenuItems>
          <a href="#faq">Faq</a>
        </MenuItems>
        <MenuItems>
          <a href="#team">Team</a>
        </MenuItems>
      </FlexBox>
      <Social />
    </StyledHeader>
  );
};

export default Header;
