import Title from "components/Title";
import ReactPlayer from "react-player";
import styled from "styled-components";
import "styles/trailer.scss";

const Trailer = () => {
  return (
    <div id="trailer">
      <Title>Trailer</Title>
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://www.youtube.com/embed/TxRw-QvcZzU?vq=hd1080"
          width="100%"
          height="100%"
          config={{
            youtube: {
              playerVars: { controls: true, autoplay: true },
            },
          }}
        />
      </div>
    </div>
  );
};

export default Trailer;
